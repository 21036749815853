<template>
  <header v-if="isConnected" class="header-container">
    <!-- <a href="https://100ms.live/" rel="noreferrer" target="_blank">
      <img class="logo" src="../assets/logo_white.png" alt="100ms Logo" />
    </a> -->
    <div class="title-container">
      <img class="logo" src="../assets/logo_white.png" />
      <p class="flight-portal">Flight Portal</p>
      <!-- <h1 class="app-title">Skyhost</h1> -->
      <!-- <h1 class="app-title">Skyhost</h1>
      <p class="flight-portal">Flight Portal</p> -->
    </div>

    <!-- <h3 class="title">Conferencing</h3> -->

    <div v-if="hmsStore.hmsIsConnectedToRoom" class="right">
      <!-- <button
        title="Leave"
        class="btn-danger leave-btn space-right"
        @click="leaveRoom"
        v-if="hmsStore.hmsIsConnectedToRoom"
      >
        Leave Room
      </button> -->
      <!-- <CallStatus /> -->
      <button class="leave-button" @click="leaveRoom">
        <img class="logout" src="../assets/log-out.png" />
      </button>
    </div>
  </header>
</template>

<script setup>
import { computed } from "vue";
// import { useRouter } from "vue-router";
import { hmsActions } from "@/utils/hms";
import { useHmsStore } from "@/stores/hms";
import { amplitude } from "../plugins/amplitude";
// import CallStatus from "./CallStatus.vue";

// const router = useRouter();
const hmsStore = useHmsStore();
const isConnected = computed(() => hmsStore.hmsIsConnectedToRoom);
console.log("isConnected", isConnected);

async function leaveRoom() {
  await hmsActions.leave();
  amplitude.track("Leave Flight", {
    type: "leave_flight",
  });
  // router.push("/");
}
</script>

<style lang="scss" scoped>
.header-container {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: none;
}

.right {
  display: flex;
  align-items: center;
}

.space-right {
  margin-right: 10px;
}

.title {
  text-align: center;
  font-size: 2rem;
}

.title-container {
  margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.app-title {
  font-size: 32px;
  color: #fff;
  margin-bottom: 2px; /* Reduce the gap */
}

.leave-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f97474;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  margin-left: 20px;
  border-width: 0;
  &:hover {
    opacity: 0.8; /* Reduced opacity on hover */
  }
}

.logo {
  width: 32px;
  height: 32px;
}

.logout {
  width: 16px;
  height: 16px;
}

.flight-portal {
  font-size: 18px;
  font-weight: 100;
  color: #f0f0f0;
  text-align: left;
}
</style>
