<template>
  <div class="gallery">
    <div
      v-for="peer in hmsStore.hmsPeers"
      :key="peer.id"
      class="tile"
      :class="{ first: isTalking === peer.id }"
    >
      <UPeer :peer="peer" />
    </div>
  </div>
</template>

<script setup>
import { useHmsStore } from "@/stores/hms";
import UPeer from "./UPeer.vue";
const hmsStore = useHmsStore();

let isTalking = "";
</script>

<style lang="scss" scoped>
.gallery {
  display: flex;
  justify-content: center;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Light background for visibility */
  backdrop-filter: blur(10px); /* Apply blur effect */
  border-radius: 30px;
}
</style>
