<template>
  <div>
    <!-- <h1 class="app-title">Skyhost</h1> -->
    <div v-show="isStreaming">
      <OvenPlayer @streaming-updated="handleStreamingUpdate" />
    </div>
    <!-- <div v-show="!isStreaming" class="centered-box">
      <Map :eventDetails="eventDetails" />
    </div> -->
    <!-- <GalleryView v-if="!hmsStore.hmsIsSomeoneScreenSharing" /> -->
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
// import Map from "./Map.vue";
import OvenPlayer from "./OvenPlayer.vue";

const isStreaming = ref(false);

const props = defineProps({
  eventDetails: {
    type: Object,
    default: () => ({}),
  },
});

watch(
  () => props.eventDetails,
  (newDetails) => {
    console.log("Received eventDetails in UConference:", newDetails);
  },
  { immediate: true }
);

function handleStreamingUpdate(newStreamingStatus) {
  isStreaming.value = newStreamingStatus;
}
</script>

<style lang="scss" scoped>
.screenshare {
  &-view {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-around;
    @media (max-width: 600px) {
      flex-direction: column;
    }

    & > .screenshare {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex: 1 auto;
      @media (max-width: 600px) {
        flex-basis: auto;
        height: unset;
      }
    }

    & > .gallery {
      flex: 1 20%;
      @media (max-width: 600px) {
        flex-basis: auto;
        height: 20%;
        flex-grow: 1;
      }
    }
  }
}
.centered-box {
  flex: 1;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  // background-color: red;
  z-index: 0; /* Set z-index to appear on top of other elements */
}
</style>
