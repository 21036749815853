import { defineStore } from "pinia";

export const useSessionStore = defineStore("session", {
  state: () => ({
    userId: null,
    userName: null,
    userEmail: null,
  }),
  actions: {
    setUserId(userId) {
      this.userId = userId;
    },
    setUserName(userName) {
      this.userName = userName;
    },
    setUserEmail(userEmail) {
      this.userEmail = userEmail;
    },
    clearUser() {
      this.userId = null;
      this.userName = null;
      this.userEmail = null;
    },
  },
});
