<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container">
    <main class="main-container">
      <!-- Left side content -->
      <div>
        <div class="title-container">
          <img src="@/assets/logo_white.png" alt="Logo" class="logo" />
          <h2 class="app-title">Welcome to Skyhost</h2>
          <h4 class="subheading">Sign in to your account</h4>
        </div>

        <div class="error-message-container">
          <label class="error-message">{{ errorMessage }}</label>
        </div>

        <input
          type="text"
          id="email"
          v-model="email"
          placeholder="you@example.com"
        />
        <input
          type="password"
          id="password"
          v-model="password"
          placeholder="********"
        />
        <button
          type="submit"
          class="join-button"
          @click="submitForm"
          :disabled="isFormEmpty || isSubmitting"
        >
          Login
        </button>

        <div class="signup-container">
          <h4 class="subheading">
            Don't have an account?
            <router-link class="signup-link" to="/signup">Sign Up</router-link>
          </h4>
        </div>
      </div>
    </main>

    <!-- hide this view when is mobile device -->
    <div v-if="!isMobileDevice" class="right-container">
      <swiper
        :slides-per-view="1"
        centered-slides="true"
        class="mySwiper"
        :modules="modules"
        :loop="true"
        :autoplay="{
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }"
        style="
          --swiper-pagination-color: white;
          --swiper-pagination-bullet-inactive-color: white;
        "
        :pagination="{
          clickable: true,
        }"
      >
        <swiper-slide v-for="(slide, index) in slides" :key="index">
          <div class="slide-content">
            <h2 class="slide-title">{{ slide.title }}</h2>
            <p class="slide-description">{{ slide.description }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { useSessionStore } from "@/stores/session";
import { isMobile } from "@/utils/helper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
import { amplitude } from "../plugins/amplitude";
import "swiper/css";
import "swiper/css/pagination";

export default {
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      isSubmitting: false,
      slides: [
        {
          title: "Live Stream From Your Drone",
          description:
            "Host real-time drone flights with sub-second latency for a seamless, delay-free experience.",
        },
        {
          title: "Live Audio Conversations",
          description:
            "Engage directly with viewers through real-time audio while showcasing your drone stream.",
        },
        {
          title: "Clean Feed without OSD",
          description:
            "Stream a clean feed with no on-screen displays, offering an unobstructed drone footage.",
        },
        {
          title: "No Extra Hardware Needed",
          description:
            "Skyhost offers high-quality live streams without the need for expensive hardware.",
        },
        {
          title: "Get a Link You Can Share",
          description:
            "Share the link with your audience, and they can join in real-time from anywhere.",
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Autoplay],
    };
  },
  computed: {
    isFormEmpty() {
      return !this.email || !this.password;
    },
    isMobileDevice() {
      return isMobile();
    },
  },
  methods: {
    submitForm() {
      console.log("Form submitted");
      this.isSubmitting = true;

      amplitude.track("Login Form Submitted", {
        type: "login",
      });

      if (this.isFormEmpty) {
        this.errorMessage = "Please fill in all the fields";
        this.isSubmitting = false;
        return;
      }

      if (!this.isValidEmail(this.email)) {
        this.errorMessage = "Please enter a valid email address";
        this.isSubmitting = false;
        return;
      }

      this.errorMessage = "";

      const sessionStore = useSessionStore();

      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          signInWithEmailAndPassword(auth, this.email, this.password)
            .then((userCredential) => {
              const user = userCredential.user;
              sessionStore.setUserId(user.uid);
              console.log("Signed in as:", user.email);
              this.$router.push("/home");
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(errorCode);
              console.log(errorMessage);

              this.isSubmitting = false;

              if (errorCode === "auth/invalid-email") {
                this.errorMessage = "Invalid email";
                return;
              } else if (errorCode === "auth/operation-not-allowed") {
                this.errorMessage = "Operation not allowed";
                return;
              } else if (errorCode === "auth/user-disabled") {
                this.errorMessage = "User disabled";
                return;
              } else if (errorCode === "auth/user-not-found") {
                this.errorMessage = "User not found";
                return;
              } else if (errorCode === "auth/wrong-password") {
                this.errorMessage = "Wrong password";
                return;
              } else if (errorCode === "auth/too-many-requests") {
                this.errorMessage = "Too many requests";
                return;
              } else if (errorCode === "auth/network-request-failed") {
                this.errorMessage = "Network request failed";
                return;
              } else if (errorCode === "auth/weak-password") {
                this.errorMessage = "Weak password";
                return;
              } else {
                console.log("Unknown error:", errorCode);
                this.errorMessage = "Unknown error";
                return;
              }
            });
        })
        .catch((error) => {
          console.error("Error setting persistence:", error);
          this.isSubmitting = false;
        });
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>

<style scoped>
html,
body {
  overflow: hidden;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  text-align: center;
  overflow: hidden;
}

.logo {
  height: 30px;
  margin-bottom: 2rem;
}

.slide-title {
  font-weight: 100;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.slide-description {
  font-weight: 100;
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5;
  color: #b9b5b5;
}

.image {
  height: 30%;
  width: 30%;
  border-radius: 24px;
  margin-bottom: 2rem;
  /* height: 100%; */
}

.header {
  padding: 1rem;
  background-color: #000;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  margin-bottom: 2rem;
  padding: 10px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgb(238, 238, 238);
  margin: 1rem 0;
}

.error-message-container {
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-message {
  color: rgb(209, 88, 88);
  font-size: small;
  margin-bottom: 1rem;
}

.app-title {
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
}

.main-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.main-content {
  flex-grow: 1;
  display: flex;
  width: 300px;
  height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}

.label {
  font-weight: bold;
  color: #a8a8a8;
  font-size: 0.7rem;
}

.value {
  color: #000;
  font-size: 0.8rem;
}

.title {
  width: 400px;
  margin-bottom: 0.5rem;
}

.subheading {
  width: 100%;
  text-wrap: wrap;
  font-size: small;
  text-align: center;
  color: #b9b5b5;
}

.place {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 0.5rem;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  display: block;
  width: 100%;
  height: 45px;
  font-size: 14px;
  padding: 20px;

  color: inherit;
  font-family: inherit;

  background: #13141a;

  border-radius: 12px;
  border: 2px solid transparent;
  outline: none;
  margin-bottom: 20px;
}

input:focus {
  outline: none;
  border-color: #1565c0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #13141a inset !important;
  -webkit-text-fill-color: #fff !important;
  border-color: #13141a;
}

input:-webkit-autofill:focus {
  border-color: #1565c0;
}

.join-button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #1565c0;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  height: 45px;
  width: 100%;
  margin-bottom: 1rem;
  &:hover {
    background-color: #104e93;
  }
  &:disabled {
    background-color: #104e93;
    color: #aaa;
    cursor: not-allowed;
  }
}

.btn-primary:hover {
  background-color: #0056b3;
}

.signup-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 3rem;
}

.signup-text {
  font-size: 0.8rem;
  color: #a8a8a8;
}

.signup-link {
  font-size: 0.8rem;
  color: #fff;
}

.right-container {
  flex: 1;
  display: flex;
  max-width: 50%;
  justify-content: center;
  align-items: center;
  background-color: #13141a;
}

.mySwiper {
  width: 70%;
  height: 50%;
}

.slide-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  padding: 1rem;
  font-size: 0.7rem;
  color: gray;
}
</style>
