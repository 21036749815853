<template>
  <div class="container">
    <div class="form-container">
      <form @submit.prevent="join">
        <div class="title-container">
          <h2>Create a new Flight</h2>
          <button class="circle-button" @click="goHome">
            <vue-feather
              type="x"
              style="width: 15px; height: 15px"
            ></vue-feather>
          </button>
        </div>

        <div class="input-group">
          <label for="eventName">Event name</label>
          <input
            ref="eventNameInput"
            v-model="eventName"
            type="text"
            name="eventName"
            id="eventName"
            placeholder="Sacred Peak Tour"
            @keydown.enter.prevent
          />
        </div>

        <div class="input-group">
          <label for="location">Location</label>
          <input
            ref="autocompleteInput"
            v-model="location"
            type="text"
            name="location"
            id="location"
            placeholder="Lake Shoji, Yamanashi, Japan"
            @keydown.enter.prevent
          />
        </div>

        <!-- Streaming settings section -->
        <div class="streaming-settings">
          <h3>Livestream settings</h3>
          <p class="para">
            Locate the Livestream settings in DJI Fly App or DJI RC Controller
            and configure the following
          </p>
          <div class="setting">
            <span class="label">RTMP URL</span>
            <code>rtmp://52.195.218.246:1935/app/stream</code>
          </div>
          <div class="setting">
            <span class="label">RESOLUTION</span>
            <code>1080p</code>
          </div>
          <div class="setting">
            <span class="label">BITRATE</span>
            <code>5Mbps</code>
          </div>
          <div>
            <img
              src="@/assets/dji_stream_settings.png"
              class="banner"
              alt="Streaming Settings"
            />
          </div>
        </div>

        <!-- Disable button if eventName is empty -->
        <div class="button-container">
          <button
            type="submit"
            class="join-button"
            :disabled="isFormInvalid || joinInProgress"
          >
            {{ joinInProgress ? "Creating..." : "Create Now" }}
          </button>
        </div>
      </form>
    </div>
    <div class="right-side" v-if="!isMobileDevice">
      <div ref="mapContainer" class="map-container"></div>
    </div>
  </div>
</template>

<script setup>
import { hmsActions } from "@/utils/hms";
import { ref, computed, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useHmsStore } from "@/stores/hms";
import { auth } from "@/plugins/firebase";
import { useToast, POSITION } from "vue-toastification";
import { amplitude } from "../plugins/amplitude";
import { addDoc, collection, query, getDocs, where } from "firebase/firestore";
import { db } from "../plugins/firebase";
import mapboxgl from "mapbox-gl";
import pinImage from "@/assets/pin.png";
import { isMobile } from "@/utils/helper";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZnVuY3Rpb25jYWxsIiwiYSI6ImNsdXIzcG1xaDAzd2wya3FyY2R2eWZlMGYifQ.0cccxRIsC0Ci2rljJueLFw";

const router = useRouter();
const toast = useToast();
const hmsStore = useHmsStore();
const isMobileDevice = computed(() => isMobile());

let joinInProgress = ref(false);
const mapContainer = ref(null);
const eventNameInput = ref(null);
let currentMarker = null; // Store the current marker reference

function goHome() {
  router.push({ name: "Home" }); // Adjust the route name as necessary
}

const currentUser = ref(null);

onMounted(() => {
  const map = new mapboxgl.Map({
    container: mapContainer.value,
    style: "mapbox://styles/functioncall/clz8hzbuj002r01r4277jhg8j",
    center: [longitude.value, latitude.value],
    zoom: 2,
    pitch: 0,
    minZoom: 2,
    maxZoom: 12,
  });

  function addMarker(latitude, longitude) {
    // Remove the previous marker if it exists
    if (currentMarker) {
      currentMarker.remove();
    }

    // Create a new marker
    const img = document.createElement("img");
    img.src = pinImage;
    img.style.width = "30px";
    img.style.height = "30px";

    currentMarker = new mapboxgl.Marker({
      element: img,
      anchor: "bottom",
      offset: [0, 0],
    })
      .setLngLat([longitude, latitude])
      .addTo(map);

    map.easeTo({
      center: [longitude, latitude],
      easing: (t) =>
        t < 0.5 ? 4 * Math.pow(t, 3) : 1 - Math.pow(-2 * t + 2, 3) / 2, // Eases in very slow and eases out very slow
      zoom: 10,
      duration: 5000,
      pitch: 0,
    });
  }

  // Watch for changes in latitude and longitude
  watch([latitude, longitude], ([newLat, newLng]) => {
    if (newLat && newLng) {
      addMarker(newLat, newLng);
    }
  });
});

onMounted(() => {
  if (eventNameInput.value && !isMobileDevice.value) {
    eventNameInput.value.focus();
  }
});

onMounted(async () => {
  const user = auth.currentUser;
  if (user) {
    try {
      const q = query(collection(db, "users"), where("userId", "==", user.uid));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDetails = querySnapshot.docs[0].data();
        currentUser.value = userDetails;
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  } else {
    console.log("No user is currently signed in.");
  }
});

async function fetchToken() {
  try {
    const response = await fetch(
      // "http://localhost:3000/generate-token",
      "https://skyhost.ai/generate-token",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (!data.token) {
      throw new Error("Token is empty or undefined.");
    }
    return data;
  } catch (error) {
    console.error("Failed to fetch token", error);
    return null;
  }
}

let token = "";
let data = null;

const joinAsMuted = false;
const settings = joinAsMuted ? { isAudioMuted: true, isVideoMuted: true } : {};

const eventName = ref("");
const location = ref("");
const latitude = ref("");
const longitude = ref("");

onMounted(() => {
  // eslint-disable-next-line no-undef
  const autocomplete = new google.maps.places.Autocomplete(
    document.getElementById("location")
  );
  autocomplete.addListener("place_changed", () => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      latitude.value = place.geometry.location.lat();
      longitude.value = place.geometry.location.lng();
    }
    location.value = place.formatted_address;
  });
});

async function join() {
  console.log("Attempting to join...:", eventName.value);
  if (!currentUser.value) {
    toast.error("User is not authenticated.", {
      position: POSITION.BOTTOM_LEFT,
    });
    return;
  }

  joinInProgress.value = true;
  amplitude.track("New Flight", {
    type: "new_flight",
    eventName: eventName.value,
    location: location.value,
  });

  if (!eventName.value) {
    toast.error("Please enter the event name.", {
      position: POSITION.BOTTOM_LEFT,
    });
    return;
  }

  if (!location.value) {
    toast.error("Please enter the flight location.", {
      position: POSITION.BOTTOM_LEFT,
    });
    return;
  }

  data = await fetchToken();
  token = data.token;

  if (!token) {
    toast.error("Token is invalid or not fetched.", {
      position: POSITION.BOTTOM_LEFT,
    });
    return; // Exit if no valid token is available
  }

  try {
    hmsStore.token = token;
    toast.clear(); // It's a new start
    await hmsActions.join({
      userName: currentUser.value.name,
      authToken: token,
      rememberDeviceSelection: true,
      settings,
    });

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create flight entry in Firestore
    const flightData = {
      creatorUserId: auth.currentUser.uid,
      eventName: eventName.value,
      roomCode: data.roomCode,
      token: token,
      // roomId: data.roomId,
      location: location.value,
      latitude: latitude.value,
      longitude: longitude.value,
      createdAt: new Date(),
      timezone: timezone,
    };
    await addDoc(collection(db, "flights"), flightData);

    router.push({
      name: "FlightPortal",
      params: {
        roomCode: data.roomCode,
      },
    });
  } catch (err) {
    toast.error(`Can't join => ${err.message}: ${err.description || ""}`, {
      position: POSITION.BOTTOM_LEFT,
    });
  } finally {
    joinInProgress.value = false;
  }
}

const isFormInvalid = computed(() => {
  return (
    !eventName.value || !location.value || !latitude.value || !longitude.value
  );
});
</script>

<style scoped>
.container {
  display: flex;
  height: 100vh;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  vertical-align: middle;
  margin-bottom: 30px;
}

.form-container {
  width: 30rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.right-side {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.map-container {
  width: 100%;
  height: 100%;
}

form {
  width: min(100%, 500px);
  height: 100%; /* Ensure form takes full available height */
  padding: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align elements from the top */
  align-items: center;
}

h2 {
  font-size: 28px;
  font-weight: 500;
  color: antiquewhite;
  text-align: left;
  width: 100%;
}

.para {
  font-size: 14px;
  color: #eee;
  margin-bottom: 0.5rem;
  line-height: 1.2rem;
}

.input-group {
  width: 100%;
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #aaa;
  font-size: 14px;
}

input {
  width: 100%;
  height: 45px;
  font-size: 14px;
  padding: 20px;
  background: #13141a;
  color: inherit;
  border-radius: 12px;
  border: 2px solid transparent;
}

input::placeholder {
  color: #aaa;
}

input[type="text"]:focus {
  outline: none;
  border-color: #1565c0;
}

input[type="number"]:focus {
  outline: none;
  border-color: #1565c0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

.coordinates-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
}

.small-input-group {
  width: 45%;
}

.circle-button {
  background-color: #13141a;
  border: none;
  border-radius: 12px;
  color: white;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-color: #1565c0;
}

.circle-button:hover {
  background-color: #313132;
}

.streaming-settings {
  width: 100%;
  margin-bottom: 30px;
  color: #fff;
}

.streaming-settings h3 {
  font-size: 18px;
  color: #aaa;
  margin-bottom: 1rem;
}

.setting {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.setting .label {
  font-weight: bold;
  font-size: 12px;
  color: #aaa;
}

.setting .value {
  color: #eee;
  font-size: 14px;
}

.banner {
  width: 100%;
  height: auto;
  border-radius: 6px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

code {
  background-color: #2d2d2d;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
}

.join-button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #1565c0;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  height: 45px;
  width: 100%;
  &:disabled {
    background-color: #104e93;
    color: #aaa;
    cursor: not-allowed;
  }
}

.button-container {
  margin-top: auto;
  width: 100%;
}
</style>
