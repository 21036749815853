<template>
  <div class="home">
    <div v-if="loading"></div>
    <div v-else class="layout">
      <div v-if="hmsStore.hmsIsConnectedToRoom" class="header">
        <UHeader />
      </div>
      <div v-if="hmsStore.hmsIsConnectedToRoom" class="conference-container">
        <UConference :eventDetails="eventDetails" />
      </div>
      <div v-if="hmsStore.hmsIsConnectedToRoom" class="footer">
        <div v-if="showShareLink" class="share-link">
          <div class="share-header">
            <h2 class="share-title">Your Meeting's Ready</h2>
            <button class="close-btn" @click="hideShareLink">
              <vue-feather type="x" size="24" />
            </button>
          </div>
          <p class="share-instruction">
            Share this link with others you want in the meeting
          </p>
          <div class="share-link-container">
            <input class="share-input" v-model="shareLink" readonly />
            <button class="copy-btn" @click="copyLink">
              <vue-feather type="copy" size="16" />
            </button>
          </div>
        </div>
        <UFooter :eventDetails="eventDetails" />
      </div>
      <JoinFlight v-else />
    </div>
  </div>
</template>

<script setup>
import JoinFlight from "@/components/JoinFlight.vue";
import UConference from "@/components/UConference.vue";
import UFooter from "@/components/UFooter.vue";
import UHeader from "@/components/UHeader.vue";
// import UNotification from "./components/UNotification.vue";
import { hmsActions } from "@/utils/hms";
import { useHmsStore } from "@/stores/hms";
import { useRoute } from "vue-router";
import { ref, onMounted, computed } from "vue";
import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../plugins/firebase";
import { useSessionStore } from "../stores/session";

function leaveRoom() {
  hmsActions.leave();
}

async function getEventDetails(roomCode) {
  try {
    const q = query(
      collection(db, "flights"),
      where("roomCode", "==", roomCode)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const flightDetails = querySnapshot.docs[0].data();
      return { id: querySnapshot.docs[0].id, ...flightDetails };
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching flight details: ", error);
    return null;
  }
}

const route = useRoute();
const eventDetails = ref(null);
const loading = ref(true);
const sessionStore = useSessionStore();

onMounted(async () => {
  eventDetails.value = await getEventDetails(route.params.roomCode);
  const userId = sessionStore.userId;
  console.log("User ID:", userId);
  if (userId) {
    showShareLink.value = userId === eventDetails.value.creatorUserId;
  }
  loading.value = false;
});

const shareLink = computed(() => {
  return `skyhost.ai/${route.params.roomCode}`;
});
const showShareLink = ref(
  // auth.currentUser.uid === props.eventDetails.creatorUserId
  false
);
function copyLink() {
  navigator.clipboard.writeText(shareLink.value).then(() => {
    alert("Link copied to clipboard!");
  });
}
function hideShareLink() {
  showShareLink.value = false;
}

const hmsStore = useHmsStore();

hmsStore.initialiseSubscribe();

window.addEventListener("unload", () => leaveRoom());
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}

.footer {
  z-index: 20;
  position: absolute;
  bottom: 0;
  width: 100%;
  flex-shrink: 0;
}

* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;

  background-color: #000;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

.btn-primary {
  padding: 6px 14px;

  background-color: #1565c0;

  border: 1px solid transparent;
  border-radius: 4px;

  font-family: inherit;
  font-size: 14px;
  color: white;
  &:hover {
    background-color: #104e93;
  }
}

.btn-danger {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 14px;

  background-color: #f44336;

  font-family: inherit;
  font-size: 14px;
  color: white;

  border: 1px solid transparent;
  border-radius: 4px;

  cursor: pointer;
  &:hover {
    background-color: #b9352b;
  }
}

.share-link {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin-left: 40px;
  margin-bottom: 10px;
  position: relative;
  font-family: "Arial", sans-serif;
}

.share-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.share-title {
  font-size: 20px;
  color: #333;
}

.close-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.share-instruction {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
}

.share-link-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.share-input {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
}

.copy-btn {
  margin-left: 10px;
  padding: 10px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.copy-btn:hover {
  background-color: #d0d0d0;
}

:root {
  --toastContainerTop: auto;
  --toastContainerRight: auto;
  --toastContainerBottom: 3rem;
  --toastContainerLeft: 3rem;
  --toastWidth: 20rem;
}
.Vue-Toastification__toast {
  &.Vue-Toastification__toast {
    &--warning {
      background-color: #ffab00;
    }
    &--error {
      background-color: #cc525f;
    }
    &--success {
      background-color: #36b37e;
    }
    &--info {
      background-color: #61758f;
    }
  }
}
</style>
