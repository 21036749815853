<template>
  <div ref="avatar" :style="{ background: avatarColor }" class="avatar">
    {{ initials }}
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, ref } from "vue";
import { addAudioBorder } from "@/utils/audioLevel";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  peerId: {
    type: String,
    required: true,
  },
});

const avatar = ref(null); // Ensure this is null initially

onMounted(() => {
  if (avatar.value) {
    addAudioBorder(props.peerId, avatar.value);
  } else {
    console.error("Avatar element is not available");
  }
});

const initials = computed(() => (props.name || "").charAt(0).toUpperCase());

const colors = [
  "#8BC34A",
  "#F57C00",
  "#673AB7",
  "#009688",
  "#FBC02D",
  "#616161",
  "#3F51B5",
  "#4CAF50",
  "#FFA000",
  "#795548",
  "#E91E63",
  "#2F80FF",
];
const colorIndex = ref(Math.floor(Math.random() * colors.length));
const avatarColor = computed(() => colors[colorIndex.value]);
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  width: 40px;
  height: 40px;
  font-weight: 100;
  font-size: 1.3rem;
  border-radius: 100rem;
  transition: border 0.3s ease-in-out;
}
</style>
