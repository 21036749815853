import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createPinia } from "pinia";
import "./registerServiceWorker";
import { initializeAmplitude } from "./plugins/amplitude";
import { firebaseApp } from "./plugins/firebase";
import VueFeather from "vue-feather";

initializeAmplitude();
firebaseApp;

const pinia = createPinia();
const app = createApp(App);
app.use(router);
app.use(pinia);
app.use(Toast);
app.component(VueFeather.name, VueFeather);
app.mount("#app");
