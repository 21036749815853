<template>
  <div class="home">
    <div v-if="loading"></div>
    <div v-else class="layout">
      <div v-if="hmsStore.hmsIsConnectedToRoom">
        <MConference :eventDetails="eventDetails" />
        <div v-if="isPortrait">
          <MHeader :eventDetails="eventDetails" />
        </div>
      </div>
      <JoinFlight v-else />
    </div>
  </div>
</template>

<script setup>
import JoinFlight from "@/components/JoinFlight.vue";
import MConference from "@/components/MConference.vue";
import { hmsActions } from "@/utils/hms";
import { useHmsStore } from "@/stores/hms";
import { useRoute } from "vue-router";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../plugins/firebase";
import MHeader from "../components/MHeader.vue";

function leaveRoom() {
  hmsActions.leave();
}

async function getEventDetails(roomCode) {
  try {
    console.log("Fetching event details for room code:", roomCode);
    const q = query(
      collection(db, "flights"),
      where("roomCode", "==", roomCode)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const flightDetails = querySnapshot.docs[0].data();
      return { id: querySnapshot.docs[0].id, ...flightDetails };
    } else {
      console.log("No matching documents.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching flight details: ", error);
    return null;
  }
}

const route = useRoute();
const eventDetails = ref(null);
const loading = ref(true);
const isPortrait = ref(true);

const updateOrientation = () => {
  isPortrait.value = window.matchMedia("(orientation: portrait)").matches;
  console.log("Orientation:", isPortrait.value);
};

onMounted(() => {
  updateOrientation();
  window.addEventListener("resize", updateOrientation);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateOrientation);
});

onMounted(async () => {
  eventDetails.value = await getEventDetails(route.params.roomCode);
  console.log("Event details on mount:", eventDetails.value);
  console.log("Loading state:", loading.value);
  loading.value = false;
});

// Log the query and params for debugging
console.log("Captured query params:", eventDetails.value);
console.log("Captured route params:", route.params);

const hmsStore = useHmsStore();

hmsStore.initialiseSubscribe();

window.addEventListener("unload", () => leaveRoom());
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  // background-color: #fff;
}

* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;

  background-color: #000;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

.btn-primary {
  padding: 6px 14px;

  background-color: #1565c0;

  border: 1px solid transparent;
  border-radius: 4px;

  font-family: inherit;
  font-size: 14px;
  color: white;
  &:hover {
    background-color: #104e93;
  }
}

.btn-danger {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 14px;

  background-color: #f44336;

  font-family: inherit;
  font-size: 14px;
  color: white;

  border: 1px solid transparent;
  border-radius: 4px;

  cursor: pointer;
  &:hover {
    background-color: #b9352b;
  }
}

:root {
  --toastContainerTop: auto;
  --toastContainerRight: auto;
  --toastContainerBottom: 3rem;
  --toastContainerLeft: 3rem;
  --toastWidth: 20rem;
}
.Vue-Toastification__toast {
  &.Vue-Toastification__toast {
    &--warning {
      background-color: #ffab00;
    }
    &--error {
      background-color: #cc525f;
    }
    &--success {
      background-color: #36b37e;
    }
    &--info {
      background-color: #61758f;
    }
  }
}
</style>
