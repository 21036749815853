<template>
  <footer class="control-bar">
    <div class="control-bar-left">
      <div class="time-location">
        <h3 class="current-time">{{ currentTime }} |</h3>
        <p :style="{ fontSize: locationFontSize }" class="location">
          {{ eventDetails.location }}
        </p>
      </div>
    </div>
    <div class="control-bar-center">
      <GalleryView />
      <div
        class="modal"
        v-if="deviceModal"
        ref="modal"
        @click="closeDeviceModal"
      >
        <div class="wrap">
          <div class="content">
            <DeviceSettings />
          </div>
        </div>
      </div>
    </div>
    <div class="control-bar-right">
      <button
        :class="['microphone-button', micButtonClass]"
        @click="toggleAudio"
      >
        <i :class="microphoneIcon">
          <span v-if="hmsStore.hmsIsLocalAudioEnabled"><MicIcon /></span>
          <span v-else><MicOffIcon /></span>
        </i>
      </button>
    </div>
  </footer>
  <UChat v-if="hmsStore.isChatOpen" />
</template>

<script setup>
import { hmsActions } from "@/utils/hms";
import { computed, onMounted, ref, defineProps } from "vue";
import DeviceSettings from "./DeviceSettings.vue";
import { useHmsStore } from "@/stores/hms";
import UChat from "./UChat.vue";
// import ChatIcon from "./icons/ChatIcon.vue";
import MicIcon from "./icons/MicIcon.vue";
import MicOffIcon from "./icons/MicOffIcon.vue";
// import SettingsIcon from "./icons/SettingsIcon.vue";
import GalleryView from "./GalleryView.vue";
import { amplitude } from "../plugins/amplitude";
// import { auth } from "../plugins/firebase";

const hmsStore = useHmsStore();
const micButtonClass = computed(() => {
  return hmsStore.hmsIsLocalAudioEnabled
    ? "microphone-enabled"
    : "microphone-disabled";
});

let deviceModal = ref(false);
let modal = ref();
const currentTime = ref("");

const props = defineProps({
  eventDetails: {
    type: Object,
    default: () => ({}),
  },
});

const locationFontSize = computed(() => {
  return props.eventDetails.location.length <= 18 ? "24px" : "12px";
});

function updateTime() {
  const now = new Date();
  const jstOptions = {
    timeZone: props.eventDetails.timezone,
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  let formattedTime = new Intl.DateTimeFormat("en-US", jstOptions).format(now);
  formattedTime = formattedTime.replace(/AM|PM/, (match) =>
    match.toLowerCase()
  ); // Convert AM/PM to lowercase

  currentTime.value = `${formattedTime}`;
}

async function toggleAudio() {
  amplitude.track("Toggle Audio", {
    type: "toggle_audio",
    enabled: !hmsStore.hmsIsLocalAudioEnabled,
  });
  await hmsActions.setLocalAudioEnabled(!hmsStore.hmsIsLocalAudioEnabled);
}

// const showDeviceModal = () => (deviceModal.value = true);

const closeDeviceModal = () => (deviceModal.value = false);
// const toggleChat = () => (hmsStore.isChatOpen = !hmsStore.isChatOpen);

onMounted(() => {
  modal.value?.addEventListener("click", () => (deviceModal.value = false));
  updateTime();
  setInterval(updateTime, 1000); // Update time every second
});

// watch(
//   () => props.eventDetails,
//   (newDetails) => {
//     console.log("Received eventDetails:", newDetails);
//   },
//   { immediate: true }
// );

// onMounted(() => {
//   console.log("Event details on mount:", props.eventDetails);
// });
</script>

<style lang="scss" scoped>
.share-link {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin-left: 40px;
  margin-bottom: 10px;
  position: relative;
  font-family: "Arial", sans-serif;
}

.share-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.close-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

h2 {
  font-size: 20px;
  color: #333;
}

.share-instruction {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
}

.share-link-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.share-input {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
}

.copy-btn {
  margin-left: 10px;
  padding: 10px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.copy-btn:hover {
  background-color: #d0d0d0;
}

.event-details {
  font-size: 12px;
  color: #777;
  margin-top: 10px;
}

.control-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
  // &-right {
  //   margin-right: 2rem;
  // }
}

.control-bar-left {
  flex: 0 0 30%;
  // background-color: #e0e0e0; /* Example color for the left section */
}

.time-location {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.control-bar-center {
  flex: 0 0 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.control-bar-right {
  display: flex;
  justify-content: right;
  align-items: right;
  flex: 0 0 30%;
  // background-color: #9e9e9e; /* Example color for the right section */
}

.btn-control {
  width: 45px;
  height: 45px;

  background-color: #263238;

  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  color: white;

  border: 2px solid #37474f;
  border-radius: 15%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  margin: 3px;
  &:hover {
    background-color: #192425;
  }
  &.pressed {
    background-color: #345b6e;
    &:hover {
      background-color: #2b4a5b;
    }
  }
}

.location {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #dfdfdf;
  display: inline-block;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  text-align: left;
}

.current-time {
  font-size: 28px;
  color: #fff;
  margin-left: 40px;
  font-weight: 100;
  font-family: "Public Sans", sans-serif;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.microphone-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f97474;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  border-width: 0;
  &:hover {
    opacity: 0.8; /* Reduced opacity on hover */
  }
}

.microphone-button i {
  font-size: 24px; /* Set the font size of the icon to 18px */
}

.microphone-enabled {
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Light background for visibility */
  backdrop-filter: blur(10px); /* Apply blur effect */
}

.microphone-disabled {
  background-color: #f97474; /* Red for disabled mic */
}

.modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  .wrap {
    position: relative;
    margin: 2rem;
    max-height: 100%;

    .content {
      width: 40rem;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }
}
</style>
