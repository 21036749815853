import { createRouter, createWebHistory } from "vue-router";
import LoginWithToken from "../views/LoginWithToken.vue";
import FlightPortal from "../views/FlightPortal.vue";
import FlightPortalMobile from "../views/FlightPortalMobile.vue";
import SignUp from "../views/SignUp.vue";
import Login from "../views/Login.vue";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../plugins/firebase.js";
import Home from "../views/Home.vue";
import How from "../views/How.vue";
import { isMobile } from "@/utils/helper";
import { useSessionStore } from "../stores/session.js";
// import Landing from "../views/Landing.vue";

const isMobileDevice = isMobile();

const routes = [
  // {
  //   path: "/",
  //   name: "Landing",
  //   component: Landing,
  // },
  {
    path: "/",
    name: "Landing",
    component: How,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/how-it-works",
    name: "How",
    component: How,
  },
  {
    path: "/new-flight",
    name: "NewFlight",
    component: LoginWithToken,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:roomCode",
    name: "FlightPortal",
    component: isMobileDevice ? FlightPortalMobile : FlightPortal,
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  const sessionStore = useSessionStore();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // If the route requires authentication, we use onAuthStateChanged to check auth state
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is authenticated:");
        sessionStore.setUserId(user.uid);
        next(); // User is authenticated, proceed to the route
      } else {
        console.log("User is not authenticated");
        next({ path: "/" }); // Not authenticated, redirect to login
      }
    });
  } else {
    next(); // Route does not require authentication
  }
});

export default router;
