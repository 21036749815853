import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAd3XG2yA9zODk0Xd0WwE46BqZ4LPDYRoA",
  authDomain: "skyhost-181eb.firebaseapp.com",
  projectId: "skyhost-181eb",
  storageBucket: "skyhost-181eb.appspot.com",
  messagingSenderId: "156256729453",
  appId: "1:156256729453:web:411b7566895d9a93724264",
  measurementId: "G-CQ1PLT4C6T",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp);

setPersistence(auth, browserLocalPersistence);

const db = getFirestore(firebaseApp);

export { firebaseApp, analytics, auth, db };
