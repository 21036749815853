import { selectPeerAudioByID } from "@100mslive/hms-video-store";
import { hmsStore } from "./hms";

export function addAudioBorder(peerId, element) {
  return hmsStore.subscribe((level) => {
    if (!element) {
      return;
    }
    if (element) {
      element.style.transition = "border 0.1s ease-in-out";
      if (level > 0) {
        element.style.border = "2px solid #ffffff"; // Set border when audio level is above 0
        element.style.borderRadius = "100rem";
      } else {
        element.style.border = "none"; // Remove border when no audio
      }
    }
  }, selectPeerAudioByID(peerId));
}
