<template>
  <header v-if="isConnected">
    <div class="left">
      <!-- <img class="logo" src="../assets/logo_white.png" />
      <p class="flight-portal">Flight Portal</p> -->
      <h3 class="current-time">
        {{ currentTime }} |
        <span :style="{ fontSize: locationFontSize }" class="location">{{
          eventDetails.location
        }}</span>
      </h3>
    </div>
    <!-- <div class="logo-container">
      <img class="logo" src="../assets/logo_white.png" />
      <p class="flight-portal">Flight Portal</p>
    </div> -->
  </header>

  <div class="gallery-container">
    <GalleryView />
  </div>

  <footer v-if="isConnected">
    <div v-if="hmsStore.hmsIsConnectedToRoom" class="left">
      <button
        :class="['microphone-button', micButtonClass]"
        @click="toggleAudio"
      >
        <i :class="microphoneIcon">
          <span v-if="hmsStore.hmsIsLocalAudioEnabled"><MicIcon /></span>
          <span v-else><MicOffIcon /></span>
        </i>
      </button>
    </div>

    <div v-if="hmsStore.hmsIsConnectedToRoom" class="right">
      <button class="leave-button" @click="leaveRoom">
        <img class="logout" src="../assets/log-out.png" />
      </button>
    </div>
  </footer>
</template>

<script setup>
import { computed, defineProps, ref, onMounted } from "vue";
// import { useRouter } from "vue-router";
import { hmsActions } from "@/utils/hms";
import { useHmsStore } from "@/stores/hms";
import { amplitude } from "../plugins/amplitude";
import GalleryView from "./GalleryView.vue";
import MicIcon from "./icons/MicIcon.vue";
import MicOffIcon from "./icons/MicOffIcon.vue";
// import CallStatus from "./CallStatus.vue";

// const router = useRouter();
const hmsStore = useHmsStore();
const isConnected = computed(() => hmsStore.hmsIsConnectedToRoom);
const currentTime = ref("");
console.log("isConnected", isConnected);

const props = defineProps({
  eventDetails: {
    type: Object,
    default: () => ({}),
  },
});

const locationFontSize = computed(() => {
  return props.eventDetails.location.length <= 10 ? "24px" : "12px";
});

const micButtonClass = computed(() => {
  return hmsStore.hmsIsLocalAudioEnabled
    ? "microphone-enabled"
    : "microphone-disabled";
});

async function toggleAudio() {
  amplitude.track("Toggle Audio", {
    type: "toggle_audio",
    enabled: !hmsStore.hmsIsLocalAudioEnabled,
  });
  await hmsActions.setLocalAudioEnabled(!hmsStore.hmsIsLocalAudioEnabled);
}

function updateTime() {
  const now = new Date();
  const jstOptions = {
    timeZone: props.eventDetails.timezone,
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  let formattedTime = new Intl.DateTimeFormat("en-US", jstOptions).format(now);
  formattedTime = formattedTime.replace(/AM|PM/, (match) =>
    match.toLowerCase()
  ); // Convert AM/PM to lowercase

  currentTime.value = `${formattedTime}`;
}

async function leaveRoom() {
  await hmsActions.leave();
  amplitude.track("Leave Flight", {
    type: "leave_flight",
  });
  // router.push("/");
}

onMounted(() => {
  updateTime();
  setInterval(updateTime, 1000); // Update time every second
});
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  padding: 30px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #000; /* Ensure footer has a background color */
}

.location {
  width: 180px;
  font-size: 12px;
  font-weight: bold;
  color: #a2a2a2;
  display: inline-block;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  word-wrap: break-word;
  text-align: left;
}

.current-time {
  font-size: 28px;
  color: #fff;
  font-weight: 100;
  font-family: "Public Sans", sans-serif;
  justify-content: center;
  align-items: center;
}

.gallery-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 40px;
  height: 100%;
}

.microphone-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f97474;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-right: 40px;
  border-width: 0;
  &:hover {
    opacity: 0.8; /* Reduced opacity on hover */
  }
}

.microphone-button i {
  font-size: 24px; /* Set the font size of the icon to 18px */
}

.microphone-enabled {
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Light background for visibility */
  backdrop-filter: blur(10px); /* Apply blur effect */
}

.right {
  display: flex;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 2rem;
}

.left {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.app-title {
  font-size: 32px;
  color: #fff;
  margin-bottom: 2px; /* Reduce the gap */
}

.leave-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f97474;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  margin-left: 20px;
  border-width: 0;
  &:hover {
    opacity: 0.8; /* Reduced opacity on hover */
  }
}

.logo {
  width: 32px;
  height: 32px;
}

.logout {
  width: 16px;
  height: 16px;
}

.flight-portal {
  font-size: 18px;
  font-weight: 100;
  color: #f0f0f0;
  text-align: left;
}
</style>
