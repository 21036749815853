<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
#app {
  overflow: hidden; /* Prevents scrolling */
}
</style>
