import * as amplitude from "@amplitude/analytics-browser";

const initializeAmplitude = () => {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return;
  }
  amplitude.init("8b55fd19bd2ea84fb74b31d1ef804c18");
};

export { amplitude, initializeAmplitude };
